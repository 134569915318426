/* src/App.css */

body {
  margin: 0;
  font-family: 'Fira Sans Condensed', sans-serif;
  background-color: #121212; /* Dark background color */
  color: #e0e0e0; /* Light text color for general text */
}

.App {
  text-align: center;
  color: #e0e0e0; /* Light text color */
}

.App-header {
  background-color: #333; /* Dark background for header */
  padding: 20px;
  color: #ffffff; /* White text color for header */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Darker shadow for header */
}

.App-header h1 {
  margin: 0;
  font-size: 2rem;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: "100%";
}

@media (max-width: 500px) {
  h1 {
    margin: 0;
    font-size: 3rem;
  }
}