/* src/components/Filter.css */

.filter-container {
    margin: 20px;
    text-align: center;
    background-color: #1e1e1e; /* Dark background to match dark theme */
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5); /* Subtle shadow */
  }
  
  .filter-label {
    font-size: 1rem;
    color: #e0e0e0; /* Light text color */
    margin-bottom: 10px;
    display: block;
  }
  
  .filter-select {
    padding: 10px 15px;
    border-radius: 4px;
    border: 1px solid #444; /* Darker border for dark theme */
    background-color: #333; /* Dark background for select */
    color: #e0e0e0; /* Light text color for select */
    font-size: 1rem;
    transition: background-color 0.3s, color 0.3s; /* Smooth transition */
  }
  
  .filter-select:hover {
    background-color: #444; /* Slightly lighter background on hover */
    color: #fff; /* Lighter text on hover */
  }
  