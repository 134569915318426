/* src/components/ImageModal.css */

.image-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .image-modal {
    position: relative;
    min-width: 300px;
    max-width: 70vw;
    max-height: 70vh;
    background: white;
    border-radius: 10px;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .image-modal-img {
    width: 100%;
    height: auto;
    max-height: calc(70vh - 40px); /* Account for padding and buttons */
    object-fit: contain;
    border-radius: 10px;
  }
  
  .image-modal-close,
  .image-modal-prev,
  .image-modal-next {
    position: absolute;
    background: transparent;
    border: none;
    font-size: 2rem;
    color: white;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
  }
  
  .image-modal-close {
    top: 10px;
    right: 20px;
  }
  
  .image-modal-prev {
    left: 20px;
  }
  
  .image-modal-next {
    right: 20px;
  }
  
  .image-modal-close:hover,
  .image-modal-prev:hover,
  .image-modal-next:hover {
    color: #6200ea;
  }
  