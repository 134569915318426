/* src/components/ModelInfoCard.css */

.model-info-card {
  padding: 20px;
  border-radius: 8px;
  background-color: #1e1e1e; /* Dark background for card */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Darker shadow */
  text-align: center;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s;
  max-width: 400px; /* Set max width */
  margin: 20px auto; /* Center the card */
  color: #e0e0e0; /* Light text color for readability */
}

.model-info-card h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #ffffff; /* White text color for headings */
}

.model-info-card p {
  font-size: 1rem;
  margin: 5px 0;
}

.model-info-card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5); /* Darker shadow on hover */
}

.model-info-card:hover p {
  color: #b0b0b0; /* Slightly lighter text on hover */
}

.model-details-card {
  margin: 5% 10%;
  padding: 5% 10%;
  font-size: 1.25rem;
  border: 1px solid white;
}

/* Responsive styles */
@media (max-width: 768px) {
  .model-info-card {
    padding: 15px;
    max-width: 90%; /* Adjust width for smaller screens */
  }

  .model-info-card h2 {
    font-size: 1.25rem;
  }

  .model-info-card p {
    font-size: 0.875rem;
  }
  .model-details-card {
    margin: 2.5% 5%;
    padding: 2.5% 5%;
    font-size: 1rem;
    border: 1px solid white;
  }
}
