/* src/components/Drawer.css */

.drawer {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  backdrop-filter: blur(40px);
  color: #e0e0e0;
  transform: translateX(100%);
  transition: transform 0.3s ease;
  box-shadow: -4px 0 8px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  z-index: 10000; /* Ensure it's above other content */
}

.drawer.open {
  transform: translateX(0);
}

.drawer-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #e0e0e0;
  font-size: 1.5rem;
  cursor: pointer;
}

.drawer-content {
  padding: 20px;
}
