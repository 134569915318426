/* src/components/LandingPage.css */

.landing-page {
    position: relative;
    height: 100vh;
    overflow: hidden;
  }
  
  .landing-video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10001;
  }
  
  .video-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px; /* Start with a small height */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    transition: height 0.3s ease; /* Smooth transition */
    z-index: 10002;
  }
  
  .landing-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 3rem; /* Adjust size as needed */
    color: white;
    z-index: 10003;
    /* The following styles create the text mask effect */
    -webkit-background-clip: text;
    background-clip: text;
  }

  .landing-video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the video covers the container */
    position: absolute; /* Positioned absolutely to fit the container */
    top: 0;
    left: 0;
  }
  .scroll-down {
    display: inline-block;
    margin-top: 20px;
    cursor: pointer;
    color: white;
    font-size: 1.2rem;
    text-decoration: none;
    position: relative;
    z-index: 10004;
  }
  
  .scroll-down svg {
    margin-left: 10px;
    vertical-align: middle;
  }
  