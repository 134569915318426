/* src/components/ImageCard.css */

.image-card {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .image-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  