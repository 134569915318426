/* src/components/Footer.css */

.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    backdrop-filter:blur(100px);
    color: #e0e0e0;
    padding: 10px 0;
    text-align: center;
    box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.3);
    z-index: 10001;
  }
  
  .footer-links {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .footer-link {
    color: #e0e0e0;
    font-size: 1.5rem;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .footer-link:hover {
    color: #fff;
  }
  