/* src/components/ImageGallery.css */

.image-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns */
  gap: 20px 30px; /* Increased gap between rows and columns */
  padding: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto; /* Center gallery horizontally */
  background-color: #121212; /* Dark background for gallery */
}

.image-card-container {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  background-color: transparent; /* Dark background for cards */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Slightly darker shadow */
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out,
    box-shadow 0.3s;
  display: flex; /* Use flexbox to center content */
  align-items: center;
  justify-content: center;
  flex: 1 1 30%; /* Adjust width as needed */
  max-width: 30%;
}

.image-card-container img {
  width: 100%; /* Ensure all images have the same width */
  height: auto; /* Height adjusts based on image aspect ratio */
  object-fit: cover; /* Crop and cover container without stretching */
  display: block; /* Remove any extra space below images */
  transition: transform 0.3s ease-in-out; /* Smooth hover effect */
}

/* Adjustments for middle column */
.middle-column {
  grid-row-end: span 1; /* Ensure the item spans only one row */
  transform: translateY(30px); /* Push the middle column down by 30px */
}

.image-card-container:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5); /* Darker shadow on hover */
}

.image-card-container:hover img {
  transform: scale(1.1);
}

/* Responsive styles */
@media (max-width: 1200px) {
  .image-gallery {
    grid-template-columns: repeat(
      3,
      1fr
    ); /* Retain three columns until 1200px */
    gap: 15px 20px;
  }
}

@media (max-width: 520px) {
  .image-gallery {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* Two columns for very small screens */
    padding: 10px;
    gap: 10px 15px; /* Adjusted gaps */
  }
  .middle-column {
    grid-row-end: span 1;
    transform: translateY(0px);
  }

  .image-gallery {
    margin: 0; /* Center gallery horizontally */
  }
}

/* .middle-column {
    grid-row-end: span 1; 
    transform: translateY(0px); 
  } */

.filter-icon {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #121212;
  color: white;
  border: none;
  font-size: 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  z-index: 2; /* Ensure it's above other content */
}

.filter-icon:hover {
  background-color: #242424;
}

.tabs {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.tab-button {
  background-color: #444;
  border: none;
  color: #e0e0e0;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s, color 0.3s;
}

.tab-button.active {
  background-color: white;
  color: black;
}

.tab-button:hover {
  background-color: #555;
}

.loader {
  text-align: center;
  padding: 20px;
  font-size: 1.5rem;
  color: #333;
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
}

.image-card-container img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px;
  transition: transform 0.3s;
}

.image-card-container img:hover {
  transform: scale(1.05);
}

.loader-container {
  text-align: center;
}

.loader-container > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
